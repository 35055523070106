.dark-mode .about-section {
    background-color: rgba(0, 0, 0, 0.2);
}

.about-section {
    display: flex;
    gap: 30px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0px 15%;
    min-height: 700px;
    background-image: url("../images/bg-dark.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.about-section .content {
    text-align: left;
    z-index: 2;

    .social_icons {
        display: flex;
        flex-direction: row;
        gap: 10px;
        color: white;

        svg {
            font-size: 1.8em;
        }
    }

    .mobile_social_icons {
        display: none;
    }

    p {
        font-size: 1.5em;
        margin-block-start: 0px;
        margin-block-end: 0px;
    }

    h1 {
        font-size: 5em;
        margin-block-start: 0px;
        margin-block-end: 0px;
    }
}

.about-section  {
    z-index: 2;
    img {
        width: 150px;
        border-radius: 50%;
    }
}
.image-wrapper {
  display: flex; /* Centra la imagen dentro de su contenedor */
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Espacio entre la imagen y el contenido */
}

.image-wrapper img {
  width: 250px; /* Ajusta el tamaño de la imagen */
  height: 250px; /* Hace que la imagen sea cuadrada */
  object-fit: cover; /* Asegura que la imagen ocupe todo el espacio sin deformarse */
  border-radius: 50%; /* Hace la imagen redonda */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Añade una sombra sutil */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Añade una transición suave */
}

.image-wrapper img:hover {
  transform: scale(1.05); /* Aumenta el tamaño un poco al hacer hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Incrementa la sombra al hacer hover */
}
.about p {
    text-align: center;
    padding-top: 30%;
    padding-bottom: 30%;
}

@media screen {
    @media (max-width: 567px) {
        .nav-left{
            padding-top: 20px;
            float:left;
        }

        .nav-left img {
            height: 35px
        }

        .nav-right {
            float:right;
        }

        .nav-right li {
            padding: 10px 15px;
            list-style:none;
            font-size:13px;
            color:white;
            width: 10%;
        }

        .nav-right ul {
            list-style-type: none;
            margin: 0px;
            padding: 0px;
        }

        body {
            width: 100%;
        }

        .about {
            padding-left: 5%;
            padding-right: 5%;
            font-size: 12px;
        }
    }

    @media (max-width:768px) {
        .about-section h1 {
            font-size: 4em !important;
            text-align: left;
        }

        .about-section p {
            text-align: left;
        }

        .container {
            display: initial;
        }

        .social_icons {
            display: none !important;
        }

        .mobile_social_icons {
            padding-top: 10px;
            display: flex !important;
            flex-direction: row;
            gap: 10px;
            color: white;
    
            svg {
                font-size: 1.8em;
            }
        }

        .about-section {
            padding-top: 150px;
            display: block;
            padding-left: 0px;
            padding-right: 0px;
        }

        .about-section .content {
            width: 90%;
            padding-left: 5%;
            padding-right: 5%;
            padding-bottom: 5%;
        }

        .about-section .image-wrapper {
            padding-left: 5%;
            width: 100%;
        }
    }
}

.twitter-icon {
  svg {
    animation: blink 1s infinite;
  }
}

@keyframes blink {
  0%, 100% {
    color: #1DA1F2;  /* Azul Twitter */
  }
  50% {
    color: #00f;     /* Azul chillón */
  }
}
